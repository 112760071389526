<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, toRefs, defineEmits, computed, nextTick } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object,
      required: true
    }
  })

  const { dataObj } = toRefs(props)
  const data = reactive({
    curIndex: 0,
    waitingDraw: false
  })

  const backgroundURL = computed(() => {
    let coverImg = dataObj.value && dataObj.value.bannerImg ? dataObj.value.bannerImg : ''
    // return JLTools.formatUrl(coverImg)
    return coverImg
  })

  const computedMinHeight = () => {
    // 如果有背景图片，则计算等比例高度作为 minHeight
    if (props.dataObj.bannerImg) {
      // 计算等比例高度
      const ratio = 1200 / 1920
      const proportionalHeight = dataObj.value.imgHeight * ratio
      console.log(proportionalHeight, 'proportionalHeight')
      return `${proportionalHeight}px` // 返回动态计算后的高度
    }
    return '0px' // 如果没有背景图片，minHeight 设置为 0
  }

  const goLink = (item) => {
    const { href } = proxy.$router.resolve({
      name: 'productMain',
      query: {
        id: dataObj.value.data[data.curIndex].id
      }
    })
    window.open(href, '_blank')
  }

  // 自定义点击事件
  const customizeClick = (index) => {
    // console.log(item, '自定义点击事件')
    nextTick(() => {
      // 等待DOM更新完成后再设置新的index
      data.curIndex = index
    })
  }

  const getStarClass = (index) => {
    if (dataObj.value.data[data.curIndex].avgLevel >= index) {
      // 满星
      return 'icon-shixinwujiaoxing'
    } else if (dataObj.value.data[data.curIndex].avgLevel + 0.5 >= index) {
      // 半星
      return 'fa-star-half-alt'
    }
    // 未选中
    return 'icon-kongxinwujiaoxing'
  }

  const waitingDraw = (index) => {
    nextTick(() => {
      data.waitingDraw = true
      console.log('渲染完毕')
    })
  }

  onMounted(() => {
    waitingDraw()
  })
</script>

<template>
  <div class="wrap-box" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
    <!-- <div class="title" v-if="dataObj.title">{{ dataObj.title }}</div>
    <div class="subtitle" v-if="dataObj.subTitle">{{ dataObj.subTitle }}</div> -->
    <div class="container">
      <JLImg class="image" :src="backgroundURL" :style="{ minHeight: computedMinHeight() }"></JLImg>
      <template v-if="data.waitingDraw">
        <div
          v-for="(item, index) in dataObj.data"
          :key="index"
          class="custom-box"
          :style="{ top: item.verticalCoordinateNumber + '%', left: item.horizontalCoordinateNumber + '%' }"
        >
          <div class="dian-box" :class="data.curIndex === index ? 'animated-box' : ''" @click="customizeClick(index)"></div>
        </div>
        <div
          class="shop-card"
          :style="{ top: dataObj.data[data.curIndex].verticalCoordinateNumber + '%', left: dataObj.data[data.curIndex].horizontalCoordinateNumber + '%' }"
          @click="goLink"
        >
          <div class="rowSC">
            <div class="img-box rowCC">
              <JLImg class="pro-img" :src="dataObj.data[data.curIndex].coverImg" />
            </div>
            <div class="right">
              <div class="shop-title text_hide2">{{ dataObj.data[data.curIndex].name }}</div>
              <div class="amount-text">{{ JLTools.forPrice(dataObj.data[data.curIndex].amount) }}</div>
              <div class="rating">
                <span v-for="n in 5" :key="n">
                  <i class="iconfont wujiaoxing" :class="['icon-kongxinwujiaoxing', getStarClass(n)]"></i>
                </span>
                <span>({{ dataObj.data[data.curIndex].commentCount }})</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="less" scoped>
  .wrap-box {
    width: 100%;
    /* min-height: 530px; */
    max-width: var(--minWidth);
    margin: 0 auto;
    background: #fff;
    .title {
      font-weight: 600;
      font-size: 22px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: var(--subTitleColor);
      padding-bottom: 32px;
    }
  }

  .container {
    position: relative;
    max-width: var(--minWidth);
    height: auto; /* 高度自适应 */
    margin: 0 auto;
    border-radius: 5px;
    .image {
      width: 100%;
      border-radius: 4px;
    }
  }

  .custom-box {
    position: absolute;
    .dian-box {
      position: relative;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: rgba(238, 222, 212, 1);
      cursor: pointer;
    }
  }

  .animated-box {
    animation: scaleAnimation 1.5s infinite;
  }

  @keyframes scaleAnimation {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }

  .shop-card {
    position: absolute;
    /* right: 30px;
    bottom: 120px; */
    margin-top: 20px;
    margin-left: 20px;
    background: #fff;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0.8;
    .img-box {
      width: 112px;
      min-height: 112px;
      position: relative;
      font-size: 0px;
      background: #f0f0f2;
      .pro-img {
        width: 100%;
      }
    }
    .right {
      min-width: 210px;
      padding: 12px 8px;
      .shop-title {
        max-width: 210px;
        font-weight: bold;
        font-size: 14px;
      }
      .amount-text {
        margin-top: 4px;
        font-size: 18px;
        font-weight: bold;
        color: var(--Zcolor);
      }
      .shop-ico {
        width: 20px;
        height: 20px;
        margin-top: 6px;
        background: url('https://obs.pricoo.pk/02c1fb09.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .rating {
      margin-top: 4px;
      .wujiaoxing {
        font-size: 14px;
        margin-right: 1px;
      }

      .icon-shixinwujiaoxing {
        color: rgb(255, 189, 22);
      }
    }
  }
</style>
