<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, toRefs, defineEmits, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    dataObj: {
      type: Object,
      required: true
    }
  })

  const { dataObj } = toRefs(props)
  const data = reactive({
    curIndex: 0
  })

  const backgroundURL = computed(() => {
    let coverImg = dataObj.value && dataObj.value.bannerImg ? dataObj.value.bannerImg : ''
    // return JLTools.formatUrl(coverImg)
    return coverImg
  })

  const bannerClass = computed(() => {
    return dataObj.value.bannerShowType === 'leftSmall' ? 'left-small-box' : 'left-big-box'
  })

  const goLink = (item) => {
    // console.log('跳转', item, isLink())
    if (!isLink(item)) return
    if (item.linkUrl === 'activityLink') {
      return linkUrl(item.linkAddress)
    }

    const { href } = proxy.$router.resolve({
      name: 'productMain',
      query: {
        id: item.id
      }
    })
    window.open(href, '_blank')
  }

  const linkUrl = (link) => {
    if (!link) return
    const url = urlCompletion(link)
    window.open(url, '_blank')
  }

  // 判定是否可跳转
  const isLink = (item) => {
    // console.log('是否可以跳转')
    let isLink = false
    if (item.linkUrl === 'goodDetail' && item.id) {
      isLink = true
    }
    if (item.linkUrl === 'activityLink' && item.linkAddress) {
      isLink = true
    }
    return isLink
  }
</script>

<template>
  <div class="wrap-box" :style="{ marginBottom: dataObj.distance ? dataObj.distance + 'px' : '0' }">
    <div class="title" v-if="dataObj.title">{{ dataObj.title }}</div>
    <div class="subtitle" v-if="dataObj.subTitle">{{ dataObj.subTitle }}</div>
    <div class="container rowSC" :class="bannerClass">
      <template v-for="(item, index) in dataObj.data" :key="index">
        <div class="rowSC card-box">
          <div class="image-box rowSC">
            <JLImg class="image" :src="item.coverImg"></JLImg>
          </div>
          <div class="right-box columnBS">
            <div>
              <div class="p1">{{ item.cardTitle }}</div>
              <div class="p2">{{ item.cardDescription }}</div>
            </div>
            <div class="btn hr-primary-btn" @click="goLink(item)">{{ item.buttonText }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="less" scoped>
  .wrap-box {
    width: 100%;
    min-height: 200px;
    max-width: var(--minWidth);
    margin: 0 auto;
    background: #fff;
    .title {
      font-weight: 600;
      font-size: 22px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: var(--subTitleColor);
      padding-bottom: 32px;
    }
  }

  .container {
    position: relative;
    max-width: var(--minWidth);
    height: auto; /* 高度自适应 */
    margin: 0 auto;
    .card-box {
      background: #f4faff;
      border-radius: 6px;
      overflow: hidden;
      .image-box {
        .image {
          height: 266px;
        }
      }
      .right-box {
        height: 266px;
        padding: 32px;
        box-sizing: border-box;
        .p1 {
          font-size: 20px;
          font-weight: 700;
        }
        .p2 {
          margin-top: 8px;
          color: #7f7f7f;
          font-size: 16px;
        }
        .btn {
          width: fit-content;
          padding: 16px 32px;
          font-size: 16px;
          color: #fff;
          font-weight: 600;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      &:nth-child(1) {
        margin-right: 16px;
      }
    }
  }

  .left-small-box {
    .card-box {
      &:nth-child(1) {
        flex: 0 0 490px;
        .image-box {
          flex: 0 0 204px;
        }
      }
      &:nth-child(2) {
        .image-box {
          flex: 0 0 288px;
        }
      }
    }
  }
  .left-big-box {
    .card-box {
      &:nth-child(1) {
        .image-box {
          flex: 0 0 288px;
        }
      }
      &:nth-child(2) {
        flex: 0 0 490px;
        .image-box {
          flex: 0 0 204px;
        }
      }
    }
  }
</style>
