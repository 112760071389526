<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    list: {
      type: Array
    }
  })

  const data = reactive({
    widthType: 1
  })

  const getAutoHeight = () => {
    let _w = document.documentElement.clientWidth || document.body.clientWidth
    // return (_w * 530) / 1920 + 'px'
    return '400px'
  }

  onMounted(() => {
    data.widthType = router.currentRoute.value.query.widthType || 1
  })
</script>

<template>
  <div class="banner" :class="{ 'hide-btn': list.length <= 1, 'max-banner': data.widthType === '2' }" v-if="list.length">
    <!-- :arrow="list.length > 1 ? 'always' : 'never'"  indicator-position="none"-->
    <el-carousel :interval="5000" :height="getAutoHeight()" arrow="never" :autoplay="true">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <a :href="item.linkAddress" alt="banner-tiaozhuan">
          <!-- <el-image class="wh100" :src="JLTools.formatUrl(item.coverImg)" fit="cover"></el-image> -->
          <JLImg class="wh100" :src="item.coverImg" fit="cover" alt="首页banner" :lazy="!index"></JLImg>
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<style lang="less" scoped>
  .banner {
    max-width: var(--minWidth);
    margin: 0 auto;
    background-color: #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
    .banner-img {
      border-radius: 5px;
    }
  }
  .max-banner {
    max-width: var(--minWidth);
  }
  .el-carousel__item a {
    width: 100%;
    height: 100%;
    margin: 0;
    display: block;
  }

  .el-carousel__item a img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  ::deep .banner.hide-btn .el-carousel__container button {
    display: none !important;
  }
  :deep(.el-carousel__indicator--horizontal .el-carousel__button) {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(196, 196, 196, 1);
  }

  /* el-carousel__indicator 的 is-active 类下的 button */
  :deep(.el-carousel__indicator.is-active button) {
    width: 20px;
    height: 10px;
    border-radius: 28px;
    background: rgba(12, 92, 168, 1);
  }
</style>
